<template>
<div>
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading && permissions.read">
      <b-col>
        <b-card class="mb-3">
          <b-button class="mr-2" variant="outline-primary" v-on:click="getXls">download</b-button>
          <b-button class="mr-2" v-if="permissions.add" variant="outline-primary" :to="{ name: 'CommAdd' }">add communication</b-button>
          <span v-if="!loading"><button-analytics-studio
            :dataProp="rows"
          /></span>
        </b-card>
        <b-card>
            <b-form-group horizontal class="mb-2">
                <b-input-group>
                  <b-form-input v-model="filter" placeholder="Type to Search" />
                    <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
                </b-input-group>
            </b-form-group>
          <b-table id="commstable"
            striped
            hover
            :items="rows"
            :fields="fields"
            :filter="filter"
            class="pointer"
            @row-clicked="showComm"
            />
        </b-card>
        <!--
        <b-card class="mb-3" v-for="row in rows" :key="row.id">
          <alert
            :row="row"
            :username="user.username"
          />
        </b-card>
        -->
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import zipcelx from 'zipcelx'

import ac from '../libs/accesscontrol'

import ButtonAnalyticsStudio from '@/components/ButtonAnalyticsStudio'

export default {
  name: 'Comms',
  beforeDestroy () {},
  components: {
    ButtonAnalyticsStudio
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.read = ac.can(this.user.acgroups).readAny('comm').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('comm').granted
    this.loadRisks()
  },
  data () {
    return {
      permissions: {
        add: false
      },
      buttonvariant: 'primary',
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'body',
          label: 'Content',
          sortable: true
        },
        {
          key: 'risks',
          label: 'Risks',
          sortable: true
        },
        {
          key: 'difficulty',
          label: 'Difficulty',
          sortable: true
        }
      ],
      filter: '',
      loading: true,
      rows: ''
    }
  },
  methods: {
    getXls: function () {
      let data = []
      const headings = [
        { value: 'Id', type: 'string' },
        { value: 'Body', type: 'string' },
        { value: 'Risk', type: 'string' },
        { value: 'Difficulty', type: 'string' },
        { value: 'Comments', type: 'string' },
        { value: 'User', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = this.rows.length; i < len; i++) {
        let row = [
          { value: this.rows[i].id, type: 'string' },
          { value: this.rows[i].body, type: 'string' },
          { value: this.rows[i].risks, type: 'string' },
          { value: this.rows[i].difficulty, type: 'string' },
          { value: this.rows[i].comments, type: 'string' },
          { value: this.rows[i].user, type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'comms', sheet: { data: data } }
      zipcelx(config)
    },
    loadRisks: async function () {
      this.$logger.debug('loading started')
      this.loading = true
      try {
        let apiName = 'sql'
        let path = '/comms'
        let response = await this.$Amplify.API.get(apiName, path)
        this.rows = response
        this.loading = false
      } catch (e) {
        this.$logger.wrn('load ERROR: ', e)
      }
    },
    showComm: function (comm, index, event) {
      this.$router.push({ name: 'Comm', params: { id: comm.id } })
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
